import type { PropsWithChildren } from "react";

import { Loader } from "./Loader";

import "./ComponentLoader.scss";

interface ComponentLoaderProps {
  isLoading?: boolean;
  width?: number;
  height?: number;
}

function ComponentLoader(props: PropsWithChildren<ComponentLoaderProps>) {
  const { isLoading, width, height, children } = props;
  const opacity = isLoading ? 0.4 : 1;
  const styles = {
    ...(width ? { width: `${width}px` } : {}),
    ...(height ? { height: `${height}px` } : {}),
  };

  return (
    <div style={styles}>
      <div className="component-loader">
        {isLoading ? <Loader type="line-scale" /> : null}

        <div style={{ opacity }}>{children || <div className="placeholder" />}</div>
      </div>
    </div>
  );
}

export default ComponentLoader;
