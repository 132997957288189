import _ from "lodash";

export default {
  prepareEnums(data) {
    if (_.isEmpty(data)) return {};
    return {
      countries: _.reduce(data.countries, (acc, c) => _.set(acc, c.code, `${c.code} - ${c.name}`), {}),
      deviceTypes: _.reduce(data.deviceTypes, (acc, d) => _.set(acc, d.key, d.name), {}),
      currencies: data.currencies,
    };
  },
};
