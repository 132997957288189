export default {
  config: {
    version: null,
    environment: "dev",
    refreshInterval: null,
    sentryEnabled: false,
    sentryDsn: null,
    appSessionKey: "appSession",
  },
  appDisplayName: "RTB House Reports",
  filters: {
    dashboard: {
      groupBy: "day",
      rangeName: "THIS_MONTH",
      countConvention: "ATTRIBUTED",
    },
    rtbCampaignStats: {
      groupBy: "day",
      rangeName: "THIS_MONTH",
      countConvention: "ATTRIBUTED",
    },
    rtbSubcampaignStats: {
      rangeName: "THIS_MONTH",
      countConvention: "ATTRIBUTED",
    },
    rtbCategoryStats: {
      rangeName: "THIS_WEEK",
      countConvention: "ATTRIBUTED",
    },
    rtbCreativeStats: {
      rangeName: "THIS_WEEK",
      countConvention: "ATTRIBUTED",
    },
    rtbDeviceStats: {
      groupBy: "deviceType",
      rangeName: "THIS_WEEK",
      countConvention: "ATTRIBUTED",
    },
    rtbCountryStats: {
      rangeName: "THIS_WEEK",
      countConvention: "ATTRIBUTED",
    },
    rtbDeduplicationStats: {
      groupBy: "day",
      rangeName: "THIS_MONTH",
    },
    rtbTopHostsStats: {
      rankedBy: "IMPS",
      rangeName: "THIS_MONTH",
    },
    rtbTopInAppsStats: {
      rankedBy: "IMPS",
      rangeName: "THIS_MONTH",
    },
    rtbLastSeenTagsStats: {
      rangeName: "THIS_MONTH",
    },
    rtbWinRateStats: {
      rangeName: "THIS_MONTH",
    },
    rtbPrivacySandboxStats: {
      groupBy: "day",
      rangeName: "THIS_MONTH",
    },
    rtbConversions: {
      rangeName: "THIS_WEEK",
      countConvention: "ATTRIBUTED",
    },
    billing: {
      rangeName: "THIS_MONTH",
    },
    offers: {},
    aggregatedReports: {
      groupBy: ["advertiser"],
      rangeName: "THIS_MONTH",
      countConvention: "ATTRIBUTED",
    },
  },
};
