import { ApiErrorTooManyRequests } from "lib/errors";

interface DashboardButtonProps {
  error: Error;
}

export function DashboardButton(props: DashboardButtonProps) {
  const { error } = props;

  if (error instanceof ApiErrorTooManyRequests) {
    return null;
  }

  return (
    <>
      <p>Click the button below to go to your dashboard</p>
      <div className="button-row">
        <a className="k-button" href="/">
          Go to dashboard
        </a>
      </div>
    </>
  );
}
