import { fetchConfig } from "@rtbhouse-apps/commonlib/dist/config-repo";

import tree from "../AppStateSvc";

const systemActions = {
  fetchConfig() {
    return fetchConfig()
      .then((data) => {
        tree.deepMerge("config", data);
        return tree.get(["config"]);
      })
      .catch((e) => {
        // net::ERR_NETWORK_IO_SUSPENDED support, when config was already fetched
        // we can ignore this error, otherwise throw it back
        const config = tree.get(["config"]);
        if (config.version === null) {
          throw e;
        }
      });
  },

  changePendingRequestsCount(delta) {
    const count = Math.max(tree.get(["http", "pendingRequestsCount"]) + delta, 0);

    tree.set(["http", "pendingRequestsCount"], count);

    if (count === 0) {
      tree.set(["loaders"], {});
    }
  },
};

tree.select(["config", "version"]).on("update", (e) => {
  if (e.data.previousData && e.data.currentData && e.data.previousData !== e.data.currentData) {
    tree.set(["nag"], true);
  }
});

tree.select(["config", "refreshInterval"]).on("update", (e) => {
  if (e.data.currentData) {
    setTimeout(systemActions.fetchConfig, e.data.currentData * 1000);
  }
});

export default systemActions;
