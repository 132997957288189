export const UTC_TIMEZONE_VALUE = "0";
export const getTimezoneDataSource = () => [
  { text: "UTC-12:00 - Baker Island Time (BIT)", value: "-12", shortText: "UTC-12:00" },
  { text: "UTC-11:00 - Samoa Standard Time (SST)", value: "-11", shortText: "UTC-11:00" },
  { text: "UTC-10:00 - Hawaii-Aleutian Standard Time (HST)", value: "-10", shortText: "UTC-10:00" },
  { text: "UTC-9:00 - Alaska Standard Time (AKST)", value: "-9", shortText: "UTC-9:00" },
  { text: "UTC-8:00 - Pacific Standard Time (PST)", value: "-8", shortText: "UTC-8:00" },
  { text: "UTC-7:00 - Mountain Standard Time (MST)", value: "-7", shortText: "UTC-7:00" },
  { text: "UTC-6:00 - Central Standard Time (CST)", value: "-6", shortText: "UTC-6:00" },
  { text: "UTC-5:00 - Eastern Standard Time (EST)", value: "-5", shortText: "UTC-5:00" },
  { text: "UTC-4:00 - Atlantic Standard Time (AST)", value: "-4", shortText: "UTC-4:00" },
  { text: "UTC-3:00 - Amazon Standard Time (AMT)", value: "-3", shortText: "UTC-3:00" },
  { text: "UTC-2:00 - Fernando de Noronha Time (FNT)", value: "-2", shortText: "UTC-2:00" },
  { text: "UTC-1:00 - Azores Time (AZOT)", value: "-1", shortText: "UTC-1:00" },
  { text: "UTC±0:00 - Greenwich Mean Time (GMT)", value: "0", shortText: "UTC±0:00" },
  { text: "UTC+1:00 - Central European Time (CET)", value: "1", shortText: "UTC+1:00" },
  { text: "UTC+2:00 - Eastern European Time (EET)", value: "2", shortText: "UTC+2:00" },
  { text: "UTC+3:00 - Moscow Standard Time (MSK)", value: "3", shortText: "UTC+3:00" },
  { text: "UTC+4:00 - Gulf Standard Time (GST)", value: "4", shortText: "UTC+4:00" },
  { text: "UTC+5:00 - Pakistan Standard Time (PKT)", value: "5", shortText: "UTC+5:00" },
  { text: "UTC+6:00 - Bangladesh Standard Time (BST)", value: "6", shortText: "UTC+6:00" },
  { text: "UTC+7:00 - Indochina Time (ICT)", value: "7", shortText: "UTC+7:00" },
  { text: "UTC+8:00 - China Standard Time (CST)", value: "8", shortText: "UTC+8:00" },
  { text: "UTC+9:00 - Japan Standard Time (JST)", value: "9", shortText: "UTC+9:00" },
  { text: "UTC+10:00 - Australian Eastern Standard Time (AEST)", value: "10", shortText: "UTC+10:00" },
  { text: "UTC+11:00 - Solomon Island Time (SBT)", value: "11", shortText: "UTC+11:00" },
  { text: "UTC+12:00 - New Zealand Standard Time (NZST)", value: "12", shortText: "UTC+12:00" },
  { text: "UTC+13:00 - Samoa Standard Time (SST)", value: "13", shortText: "UTC+13:00" },
  { text: "UTC+14:00 - Line Islands Time (LINT)", value: "14", shortText: "UTC+14:00" },
];

export const addTimezoneDetailsToHeader = (header: string, timezone?: string) => {
  if (!timezone) {
    return header;
  }

  return `${header} (${getTimezoneTextFromValue(timezone || UTC_TIMEZONE_VALUE)})`;
};

export const getTimezoneTextFromValue = (value: string) => {
  const dataSource = getTimezoneDataSource();
  const timezone = dataSource.find((item) => item.value === value);
  return timezone?.shortText;
};

export const getUserLocalTimezone = () => {
  const timezoneDataSource = getTimezoneDataSource();
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const userTimezoneOffsetInHours = -userTimezoneOffset / 60;
  const roundedOffset = Math.round(userTimezoneOffsetInHours * 2) / 2;

  return timezoneDataSource.find(({ value }) => Number(value) >= roundedOffset);
};

export const getTimezoneLabel = () => `Timezone (your local browser TZ is ${getUserLocalTimezone()?.shortText})`;
