// eslint-disable-next-line simple-import-sort/imports
import "normalize.css/normalize.css"; // If this import is after 'import { App } from "./app";' then the table on 'RtbConversionsPage' doesn't display correctly

import { ERRORS, logger } from "@rtbhouse-apps/logger-react";
import { captureConsoleIntegration, extraErrorDataIntegration } from "@sentry/react";
import { createRoot } from "react-dom/client";

import errorLogger from "lib/errorLogger";
import systemActions from "services/actions/SystemActions";
import { api } from "services/api/api";
import type { RuntimeConfig } from "services/config";

import { App } from "./app";
import { AppMaintenance } from "./app-maintenance";

import "styles/all.scss";

function initErrorLogger(config: RuntimeConfig) {
  if (!config.sentryEnabled) {
    return;
  }

  errorLogger.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    release: config.version,
    ignoreErrors: ["Network request failed", "Failed to fetch", "NetworkError"],
    denyUrls: [/^chrome:\/\//i],
    integrations: [captureConsoleIntegration({ levels: ["error"] }), extraErrorDataIntegration()],
  });
}

function initGuiLogger(config: RuntimeConfig) {
  if (!config.guiLoggerEnabled || config.environment === "e2e") {
    return;
  }

  logger.init({
    appName: "panel-reports",
    host: config.guiLoggerBaseUrl,
    localStorageKey: "@rtbhouse/logger:Reports",
    debug: config.guiLoggerDebugEnabled,
    environment: config.environment,
    dryRun: config.guiLoggerDryRunEnabled,
    // In case of any warning, send it to the Sentry.
    onWarning: (event, warningTitle, warningMessage) =>
      errorLogger.logWarning(`[GUI Logger] ${warningTitle} - ${warningMessage}. Event "${event}"`),
    onException: (error) => {
      if (error instanceof Error && error.message === ERRORS.unauthorizedResponse) {
        window.location.reload();
        return;
      }
      // In case of any other error, send it to the Sentry.
      errorLogger.logException(error);
    },
  });
}

(async function bootstrap() {
  const config = await systemActions.fetchConfig();

  initErrorLogger(config);
  initGuiLogger(config);

  const appContainer = document.getElementById("app-container");

  if (!appContainer) {
    return; // adblocking software detected
  }

  const root = createRoot(appContainer);

  try {
    await api.healthCheck();
    root.render(<App />);
  } catch {
    root.render(<AppMaintenance />);
  }
})();
