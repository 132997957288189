class RtbAppsError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ApiError extends RtbAppsError {}

export class ApiErrorForbidden extends ApiError {}

export class ApiErrorNotFound extends ApiError {}

export class ApiErrorTooManyRequests extends ApiError {}

export class AuthError extends RtbAppsError {}

export class UnexpectedError extends RtbAppsError {}
