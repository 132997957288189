import enums from "./enums";
import perm from "./permissions";

/**
 * Items which are always visible in main menu
 */
export const menuItems = [
  {
    name: "dashboard",
    config: {
      text: "Dashboard",
      url: "/#/dashboard/${advertiserHash}",
    },
    types: [enums.campaignTypes.PERFORMANCE, null],
  },
  {
    name: "rtb",
    config: {
      text: "Reports",
      url: "/#/rtb/summary-reports/${advertiserHash}",
    },
    types: [enums.campaignTypes.PERFORMANCE, null],
  },
  {
    name: "traffic-summary",
    config: {
      text: "Reports",
      url: "/#/rtb/summary-reports/${advertiserHash}",
    },
    types: [enums.campaignTypes.BRANDING],
  },
  {
    name: "billing",
    config: {
      text: "Billing",
      url: "/#/billing/summary/${advertiserHash}",
    },
  },
  {
    name: "offers",
    config: {
      text: "Offers",
      url: "/#/offers/${advertiserHash}",
    },
  },
  {
    name: "aggregated-reports",
    config: {
      text: "Aggregated Reports",
      url: "/#/aggregated-reports?currency=${currency}&campaignType=${campaignType}",
    },
    requireAnyPermission: [perm.AGGREGATED_REPORTS_PERFORMANCE, perm.AGGREGATED_REPORTS_BRANDING],
  },
  {
    name: "account",
    config: {
      text: "Account",
      url: "/#/user/account",
      cssClass: "to-right",
      imageUrl: "/images/account.png",
    },
  },
  {
    name: "logout",
    config: {
      text: "Logout",
      url: "/#/auth/logout",
      cssClass: "to-right",
      imageUrl: "/images/login.png",
    },
  },
];

/**
 * Items which are visible in submenu depending on context (e.g. advertiser context)
 */
export const submenuItems = {
  rtb: [
    {
      name: "summary-reports",
      config: { text: "Summary reports", url: "/#/rtb/summary-reports/${advertiserHash}" },
    },
    {
      name: "other-reports",
      config: { text: "Other reports" },
      subItems: [
        {
          name: "subcampaign-reports",
          config: { text: "Subcampaigns", url: "/#/rtb/subcampaign-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_CAMPAIGN_COLUMN,
        },
        {
          name: "category-reports",
          config: { text: "Categories", url: "/#/rtb/category-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_CATEGORY_COLUMN,
        },
        {
          name: "creative-reports",
          config: { text: "Creatives", url: "/#/rtb/creative-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_CREATIVE_COLUMN,
        },
        {
          name: "device-reports",
          config: { text: "Devices", url: "/#/rtb/device-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_DEVICE_TYPE_COLUMN,
        },
        {
          name: "country-reports",
          config: { text: "Countries", url: "/#/rtb/country-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_COUNTRY_COLUMN,
        },
        {
          name: "deduplication-reports",
          config: { text: "Deduplication", url: "/#/rtb/deduplication-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_DEDUPLICATION_REPORT,
        },
        {
          name: "top-hosts-reports",
          config: { text: "Top Hosts", url: "/#/rtb/top-hosts-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_TOP_HOSTS_REPORT,
        },
        {
          name: "top-in-apps-reports",
          config: { text: "Top Apps", url: "/#/rtb/top-in-apps-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_TOP_IN_APPS_REPORT,
        },
        {
          name: "last-seen-tags-reports",
          config: { text: "Last-seen Tag", url: "/#/rtb/last-seen-tags-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_LAST_SEEN_TAGS_REPORT,
        },
        {
          name: "win-rate-reports",
          config: { text: "Win-rate", url: "/#/rtb/win-rate-reports/${advertiserHash}" },
          requireFeature: enums.panelFeatures.SHOW_WIN_RATE_REPORT,
        },
      ],
    },
    {
      name: "conversions",
      config: { text: "Conversions", url: "/#/rtb/conversions/${advertiserHash}" },
      types: [enums.campaignTypes.PERFORMANCE],
      requireAnyFeature: enums.conversionsFeatures,
    },
    {
      name: "creatives",
      config: { text: "Creatives preview", url: "/#/rtb/creatives/${advertiserHash}" },
    },
    {
      name: "subcampaigns",
      config: { text: "Subcampaigns", url: "/#/rtb/subcampaigns/${advertiserHash}" },
      requireFeature: enums.panelFeatures.SHOW_CAMPAIGN_COLUMN,
    },
    {
      name: "privacy-sandbox-reports",
      config: { text: "BETA Privacy Sandbox", url: "/#/rtb/privacy-sandbox-reports/${advertiserHash}" },
      requireFeature: enums.panelFeatures.SHOW_PRIVACY_SANDBOX_REPORT,
    },
    {
      name: "settings",
      config: { text: "Settings", url: "/#/rtb/settings/${advertiserHash}" },
    },
  ],

  billing: [
    {
      name: "summary",
      config: { text: "Summary", url: "/#/billing/summary/${advertiserHash}" },
    },
    {
      name: "client-info",
      config: { text: "Invoicing data", url: "/#/billing/invoicing/${advertiserHash}" },
    },
  ],

  user: [
    {
      name: "summary",
      config: { text: "Summary", url: "/#/user/account" },
    },
    {
      name: "auth-sessions",
      config: { text: "Sessions", url: "/#/user/sessions" },
    },
  ],
};
